<!-- 首页美食 -->
<template>
  <div class="home">
    <van-search
      @change="reload"
      v-model="keyword"
      show-action
      placeholder="请输入搜索关键词"
      input-align="center"
    >
      <template #label>
        <div style="display: flex;align-items: center;height: 100%;" @click="goAddress">
          <span style="font-size: 12px;">{{city}}</span>
          <van-icon name="arrow-down" />
          <div style="height: 50%;margin-left: 5px;width: 1px;background-color: #E2E1E1;"></div>
        </div>
      </template>
      <template #action>
        <div style="display: flex;align-items: center;" @click="goMap()">
          <van-icon name="location-o" />
          <span>地图</span>
        </div>
      </template>
    </van-search>
    <!-- 轮播图 -->
    <div class="home-top">
      <van-swipe :autoplay="3000" class="home-swipe" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image.image" />
        </van-swipe-item>
      </van-swipe>
      <div class="home-classify">
        <div
          class="home-classify-item"
          v-for="(i,ins) in middleImgs"
          :key="ins"
          @click="foodsList(i.id,i.name)"
        >
          <img :src="i.image" alt />
          <span>{{i.name}}</span>
        </div>
      </div>
    </div>
    <div class="home-middle">
      <div class="home-recommend">
        <div class="recommend-title">
          <span class="recommend-title-left">热销排行榜</span>
          <!-- <span class="recommend-title-right">查看更多 ></span> -->
        </div>
        <div class="recommend-content">
          <div
            class="recommend-item"
            v-for="(item,index) in jingxuanList.slice(0,5)"
            :key="index"
            @click="indexDetails(item.id)"
          >
            <img :src="item.image" alt />
            <div class="recommend-item-rank">
              <img src="../../../assets/hotRankIcon.png" alt />
              <div>
                <span class="top">TOP</span>
                <span class="rank-numner">{{index+1}}</span>
              </div>
            </div>
            <div class="recommend-item-content">
              <p class="recommend-item-title">{{item.name}}</p>
              <div class="recommend-item-rate">
                <img src="../../../assets/hot.png" alt />
                <span>本月已售{{item.sales}}</span>
              </div>
            </div>
            <!-- <van-rate v-model="rateValue" /> -->
          </div>
        </div>
      </div>

      <div class="home-coupon">
        <div class="coupon-title">
          <span class="coupon-title-left">优惠好券</span>
          <span class="coupon-title-right" @click="moreCoupon">更多推荐 ></span>
        </div>
        <div class="coupon-content">
          <div class="content-list" v-for="(item,ins) in coupon" :key="ins">
            <div class="content-title">满{{Math.round(item.consumer_full_money)}}元可用</div>
            <div class="content-moeny">
              <text>{{item.consumer_reduce_money}}</text>
              <img src="../../../assets/coupon/moeny.png" alt />
            </div>
             <div class="content-get" v-if="item.is_take == 1" @click="getTake(item)">立即领取</div>
            <div class="content-get1" v-else>立即领取</div>
          </div>
        </div>
      </div>

      <div class="home-tab">
        <van-tabs v-model="active" sticky @click="toggle">
          <van-tab
            v-for="(item,index) in tabTitle"
            :key="index"
            :title="item.name"
            style="width: 100%;"
          >
            <waterFall :list="tabContentList" v-if="tabContentList.length>0"></waterFall>
          </van-tab>
          <van-tab
            v-for="(item,index) in tabTitles"
            :key="index"
            :title="item.name"
            :name="item.id"
            style="width: 100%;"
          >
            <waterFall :list="tabContentList" v-if="tabContentList.length>0"></waterFall>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import * as index from "@/api/index";
import waterFall from "../../../components/waterFall.vue";
import { reactive } from "vue";

import { toUrl } from "@/utils/tools";
import { Dialog, Toast } from "vant";

import { couponLst,couponTake } from "../../../api/coupon";
export default {
  components: {
    waterFall,
  },
  name: "Foods",
  data() {
    return {
      tabContentList: [],
      tabTitles: [],
      tabTitle: [
        {
          name: "猜你喜欢",
          id: 3,
        },
        {
          name: "附近美食",
          id: 4,
        },
      ],
      active: 0,
      showIndexBar: false,
      rateValue: 3,
      value: "",
      options: "",
      nullTip: "空空如也~",
      limit: 20, // 每页条数
      page: 1, // 页码
      type: 1,
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      value2: 0,
      value1: 0,
      dataList: "",
      keyword: "",
      images: [],
      middleImgs: [],
      jingxuanList: [],
      coupon:[],
    };
  },
  computed: {
    location: function () {
      return this.$store.state.app.latAndLng;
    },

    city: function () {
      if (this.$store.state.app.city.length > 0) {
        return this.$store.state.app.city[0].name;
      } else {
        return this.$store.state.app.city.city;
      }
    },
  },
  mounted() {
    this.getProductLst();
    this.getSlideshow();
    this.getCate();
    this.getHotLst();
    this.couponLst();
  },
  methods: {
     couponLst() {
      let param = {
        type: "all",
        page: 1,
        limit: 4,
        is_hide: 1,
      };
      couponLst(param).then((res) => {
        console.log(res);
        this.coupon = res.data.list;
      });
    },
    getTake(item) {
      let param = {
        id: item.id,
      };
      couponTake(param).then((res) => {
        console.log(res);
        Toast(res.msg);
        this.couponLst();
      });
    },
    moreCoupon() {
      this.$router.push({
        path: "/h5/couponCentre",
         query: {
          type: 1,
        },
      });
    },
    goAddress() {
      this.$router.push({
        path: "/h5/address",
      });
    },
    toggle(name) {
      console.log(name);
      this.active = name;
      // console.log(this.active)
      this.page = 1;
      this.getProductLst();
    },
    goMap() {
      this.$router.push({
        path: "/h5/map",
      });
    },
    // 搜索
    reload() {
      this.getProductLst();
    },
    // 轮播图
    getSlideshow() {
      let params = {
        page: 1,
        position: 0,
      };
      index.getSlideshow(params).then((res) => {
        console.log(res);
        this.images = res.data;
      });
    },
    // 分类
    getCate() {
      let params = {
        type: 1,
      };
      index.getCate(params).then((res) => {
        console.log(res);
        this.middleImgs = res.data.data;
        this.tabTitles = res.data.data;
      });
    },
    // 详情跳转
    foodsList(id, name) {
      console.log(id);
      this.$router.push({
        path: "/h5/foodslist",
        query: {
          id: id,
          name: name,
        },
      });
    },
    // 热销列表
    getHotLst() {
      let params = {
        page: this.page,
        limit: this.limit,
        sort: "sale",
        cate_type: 1,
        lng: this.location.longitude,
        lat: this.location.latitude,
      };
      index.getProductLst(params).then((res) => {
        console.log(res);
        this.jingxuanList = res.data.data;
        console.log(this.jingxuanList);
      });
    },
    // 附件推荐详情
    indexDetails(id) {
      console.log(id);
      this.$router.push({
        path: "/h5/commDetail",
        query: {
          id: id,
        },
      });
    },
    // 商品列表
    getProductLst() {
      this.tabContentList = [];
      let params = {
        page: this.page,
        limit: this.limit,
        cid: this.active,
        keyword: this.keyword,
        lng: this.location.longitude,
        lat: this.location.latitude,
      };
      if (this.active == 0) {
        params.cate_type = 1;
        params.sort = "view";
        params.cid = "";
      }
      if (this.active == 1) {
        params.cate_type = 1;
        params.sort = "distance";
        params.cid = "";
      }
      index.getProductLst(params).then((res) => {
        console.log(res);
        this.tabContentList = this.tabContentList.concat(res.data.data);
        console.log(this.tabContentList);
      });
    },

    onDownRefresh() {},
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.getIndexList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/preset.less";

.home {
  // overflow-y: scroll;
  // height: calc(100% - 50px);
  background-color: #f6f6f6;

  .home-swipe {
    width: 100%;
    height: 120px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-top {
    padding: 0 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;

    .home-classify {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;

      .home-classify-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 38px;
          height: 38px;
        }

        span {
          font-size: 12px;
          color: #1a1a1a;
        }
      }

      img {
        width: 102px;
        height: 57px;
        border-radius: 5px;
      }
    }
  }

  .home-middle {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;

    .home-middle-swipe {
      height: 75px;
      border-radius: 5px;
      margin-top: 10px;

      ::v-deep .van-swipe__indicator {
        border-radius: 0;
        width: 5px;
        height: 3px;

        background-color: #ffffff;
      }

      ::v-deep .van-swipe__indicator--active {
        border-radius: 0;
        width: 10px;
        height: 3px;

        background-color: #ffffff;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-selected {
      background-color: #ffefcd;
      padding-bottom: 10px;
      width: 100%;
      border-radius: 10px;
      margin-top: 10px;

      .selected-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        position: relative;

        span {
          padding: 5px 10px;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }

        .title-right {
          background-color: #fffcf5;
          margin: 0;
          padding: 5px 10px;
          width: 65%;
          position: absolute;
          right: 0;
          font-size: 10px;
          color: #5a4420;
          line-height: 20px;
          border-top-right-radius: 10px;
        }

        .title-right::after {
          content: " ";
          position: absolute;
          top: 0;
          left: -8px;
          border-width: 30px 8px;

          border-style: solid;

          border-color: #fffcf5 transparent transparent transparent;
        }
      }

      .selected-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .selected-item {
          width: 28%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;

          p {
            font-size: 14px;
            margin: 0;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }

          span {
            font-size: 12px;

            .item-price {
              font-size: 14px;
              color: #fc6228;
              font-weight: bold;
            }
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .home-recommend {
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px;

      .recommend-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        .recommend-title-left {
          font-size: 14px;
          font-weight: bold;
        }

        .recommend-title-right {
          font-size: 12px;
          color: #999999;
        }
      }

      .recommend-content {
        display: flex;
        width: 100%;
        margin-top: 5px;
        overflow-x: auto;
        justify-content: space-between;

        .recommend-item {
          width: 130px;
          position: relative;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-left: 5px;
          background-color: #ffffff;

          box-sizing: border-box;

          img {
            width: 100%;
            height: 85px;
            border-radius: 5px;
          }

          .recommend-item-rank {
            div {
              position: absolute;
              display: flex;
              flex-direction: column;
              width: 22px;
              height: 32px;
              top: 0;
              left: -2px;
              align-items: center;
              color: #ffffff;
            }

            .top {
              font-size: 8px;
              transform: scale(0.7);
            }

            .rank-numner {
              font-size: 12px;
            }

            img {
              position: absolute;
              left: -2px;
              top: -2px;
              width: 22px;
              height: 32px;
            }
          }

          .recommend-item-content {
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .recommend-item-rate {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              box-sizing: border-box;
              background: #fff2ec;
              border-radius: 9px;
              color: #fc6228;
              opacity: 0.8;

              padding: 3px 5px;

              img {
                width: 15px;
                height: 15px;
              }

              span {
                font-size: 10px;
                color: #fb9a00;
              }
            }

            .recommend-item-title {
              font-size: 14px;
              color: #1a1a1a;
              margin: 6px 0 3px 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .recommend-item-comment {
              font-size: 10px;
              color: #999999;
            }
          }
        }
      }
    }

    .home-recommend-bottom {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;

      .recommend-bottom-item {
        border-radius: 5px;
        background-color: #ffffff;
        width: 48%;

        .recommend-bottom-title {
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
        }

        .recommend-bottom-content {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;

          .recommend-bottom-content-item {
            position: relative;
            width: 48%;

            img {
              width: 73px;
              height: 73px;
            }
          }
        }

        .recommend-item-bottom-rank {
          background-color: #fc6228;
          position: absolute;
          top: 0;
          left: 0;
          width: 43px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 10px;
        }

        .recommend-item-bottom-title {
          font-size: 12px;
          color: #1a1a1a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          position: relative;
          top: -10px;
        }
      }
    }
  }

  .home-coupon {
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;
    .coupon-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      .coupon-title-left {
        font-size: 14px;
        font-weight: bold;
      }

      .coupon-title-right {
        font-size: 12px;
        color: #999999;
      }
    }
    //
    .coupon-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
      .content-list {
        background-image: url(../../../assets/coupon/indexC.png);
        background-size: 100% auto;
        width: 75px;
        height: 90px;
        // box-shadow: 0px 1px 1px 0px rgba(65, 63, 63, 0.38);
        display: flex;
        flex-direction: column;
        align-items: center;
        .content-title {
          font-size: 12px;
          font-weight: bold;
          color: #f84214;
          padding-top: 12px;
          transform: scale(calc(10 / 12));
        }
        .content-moeny {
          font-size: 35px;
          font-weight: bold;
          color: #fb421a;
          display: flex;
          img {
            width: 9px;
            height: 8px;
          }
        }
        .content-get {
          background-image: url(../../../assets/coupon/indexJ.png);
          background-size: 100% auto;
          width: 60px;
          height: 17px;
          font-size: 12px;
          text-align: center;
          color: #fb421a;
          transform: scale(calc(10 / 12));
        }
         .content-get1 {
            background: #cecece;
            width: 60px;
            height: 17px;
            font-size: 12px;
            border-radius: 10px;
            text-align: center;
            color: #fb421a;
            transform: scale(calc(10 / 12));
          }
      }
    }
  }

  .home-tab {
    height: 100%;
    width: 100%;

    // padding: 10px;
    ::v-deep .van-tabs__nav {
      background-color: #f6f6f6;
    }

    ::v-deep .van-tabs__line {
      width: 20px;
      background-color: #fb6228;
      bottom: 22px;
    }

    .v-waterfall-content {
      width: 100%;
      height: 100%;
      position: relative;
      box-sizing: border-box;
    }

    .v-waterfall-item {
      position: absolute;
      border-radius: 10px;
      background-color: #f2270c;
    }

    .v-waterfall-item img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
</style>
